








































































































import SiteSection from '@/components/SiteSection.vue'
import SiteShapeTree from '@/components/SiteShapeTree.vue'
import i18n from '@/setup/i18n'
import { defineComponent, computed } from '@vue/composition-api'

interface colab {
  key: string
  imgUrl: string
  name: string
  position: string
}

export default defineComponent({
  name: 'Content',

  components: {
    SiteSection,
    SiteShapeTree,
  },

  setup() {
    const colabs = computed<colab[]>(() => [
      {
        key: 'colab-1',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Christian.png',
        name: 'Lic. Christian Alejandro Martínez Leyva',
        position: i18n.t('jobs.managingDirector') as string,
      },
      {
        key: 'colab-2',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Kessec.png',
        name: 'Dr. Kessec Roberto Esquer Moreno',
        position: i18n.t('jobs.familyPsychiatrist') as string,
      },
      {
        key: 'colab-3',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Monica.png',
        name: 'Mtf. Mónica de la Torre Ramos',
        position: i18n.t('jobs.familyPsychologist') as string,
      },
      {
        key: 'colab-4',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Rene.png',
        name: 'Lic. René Calles Torres',
        position: i18n.t('jobs.teensPsychologist') as string,
      },
      {
        key: 'colab-5',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Natali.png',
        name: 'Mtra. Natali Lagarda Badilla',
        position: i18n.t('jobs.neuro') as string,
      },
      {
        key: 'colab-6',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Noelia.png',
        name: 'Lic. Noelia Fernanda Castillo Betancourt',
        position: i18n.t('jobs.psychologist') as string,
      },
      {
        key: 'colab-7',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Ana.png',
        name: 'Dra. Ana Sofía Escárcega López',
        position: i18n.t('jobs.psychiatrist') as string,
      },
      {
        key: 'colab-8',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Nathaniel.png',
        name: 'Dr. Nathaniel Arturo Alcalá',
        position: i18n.t('jobs.psychologist2') as string,
      },
      {
        key: 'colab-9',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Itzel.png',
        name: 'Lic. Itzel Dayana Romero',
        position: i18n.t('jobs.administrativeAssistant') as string,
      },
      {
        key: 'colab-10',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Oscar.png',
        name: 'Lic. Oscar Cásares',
        position: i18n.t('jobs.communication') as string,
      },
      {
        key: 'colab-11',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Ivonne.png',
        name: 'Lic. Ivonne Del Real Padilla',
        position: i18n.t('jobs.accountant') as string,
      },
      {
        key: 'colab-12',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Alejandra.png',
        name: 'Lic. Alejandra Domínguez Peralta',
        position: i18n.t('jobs.coordinator') as string,
      },
      {
        key: 'colab-13',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Kathia.png',
        name: 'Lic. Kathia Isabel Martínez Márquez',
        position: i18n.t('jobs.marketing') as string,
      },
      {
        key: 'colab-14',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Paola.png',
        name: 'Lic. Paola Edith Rodríguez Reyes',
        position: i18n.t('jobs.reception') as string,
      },
      {
        key: 'colab-15',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Tadeo.png',
        name: 'Lic. Tadeo Ramattis Tapia Chávez',
        position: i18n.t('jobs.psychologist2') as string,
      },
      {
        key: 'colab-16',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Zahid.png',
        name: 'Lic. Zahid Gabriel Bañuelos Velasco',
        position: i18n.t('jobs.materialResources') as string,
      },
      {
        key: 'colab-17',
        imgUrl: '@/assets/images/aboutUs/usersImgs/Emiliano.png',
        name: 'Lic. Emiliano Ramírez Martínez',
        position: i18n.t('jobs.publicRelations') as string,
      },
      {
        key: 'colab-18',
        imgUrl: '@/assets/images/aboutUs/usersImgs/kitchen.png',
        name: i18n.t('jobs.kitchen') as string,
        position: '',
      },
      {
        key: 'colab-19',
        imgUrl: '@/assets/images/aboutUs/usersImgs/paramedic.png',
        name: i18n.t('jobs.paramedic') as string,
        position: '',
      },
    ])
    return {
      colabs,
    }
  },
})
