










































import { defineComponent } from '@vue/composition-api'
import SiteSection from '@/components/SiteSection.vue'

export default defineComponent({
  name: 'Banner',
  components: {
    SiteSection,
  },
})
