






import { defineComponent } from '@vue/composition-api'
import Banner from '@/components/aboutUsComponents/Banner.vue'
import Content from '@/components/aboutUsComponents/Content.vue'

export default defineComponent({
  name: 'AboutUs',
  components: {
    Banner,
    Content,
  },
})
